import React from 'react';
import FooterHero from '../../components/Footer/footerHero';
import NavbarCS from '../../components/Header/NavbarCS';
import AboutHero from '../../components/About/AboutHero';
import AboutUs from '../../components/AboutUs/aboutUs';
import TeamMembers from '../../components/TeamMembers/teamMembers'
import WhatWeDo from '../../components/WhatWeDo/whatWe';
import ContactForm from '../../components/ContactForm/ContactForm';


const AboutPage = () => {
  return (
    <div>
        <NavbarCS />
        <AboutHero content="About HB Care Canada" paragragh="Discover our commitment to raising grooming standards through premium products and unparalleled support for barbers across North America."/>
        <AboutUs />
        <TeamMembers />
        <WhatWeDo />
        <ContactForm padding="0px" />
        <FooterHero />
    </div>
  )
}

export default AboutPage;