import React from 'react';
import FooterHero from '../../components/Footer/footerHero';
import NavbarCS from '../../components/Header/NavbarCS';
import Discover from '../../components/Discovers/Discover';
import HappyCustomer from '../../components/HappyCustomers/HappyCustomer';
import ContactForm from '../../components/ContactForm/ContactForm';
import ServicesHero from '../../components/Services/ServicesHero';
import ExpertTeam from '../../components/ExpertTeamMembersSection/ExpertTeam';
import Testimonials from '../../components/OurTestimonials/Testimonials';


const ServicesHome = () => {
  return (
    <div>
      <NavbarCS />
      <ServicesHero />
      <ExpertTeam />
      <Discover />
      <HappyCustomer />
      <Testimonials />
      <ContactForm />
      <FooterHero />
    </div>
  );
};

export default ServicesHome;